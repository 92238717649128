import React from 'react'
import PropTypes from 'prop-types'
import {HiOutlineCheckCircle, HiOutlineBan} from 'react-icons/hi'

const ActiveInactive = (props) => {
    const {
        t, 
        isActive,
        textToTrue,
        textToFalse
    } = props;

    return (
        isActive ? 
        <span className='text-emerald-500 text-xs flex items-center gap-1 font-semibold'><HiOutlineCheckCircle />{textToTrue}</span>
        :
        <span className='text-red-500 text-xs flex items-center gap-1 font-semibold'><HiOutlineBan />{textToFalse}</span>
    )
   
}

ActiveInactive.propTypes = {
    isActive: PropTypes.bool,
    textToTrue: PropTypes.string,
    textToFalse: PropTypes.string
}

ActiveInactive.defaultProps = {
    isActive: false,
    textToTrue: "Ativo",
    textToFalse: "Inativo"
}

export default ActiveInactive
