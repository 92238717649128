import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {
	const userAttr = useSelector((state) => state.auth.user);

	const roleMatched = useMemo(() => {
		return authority.some((role) => {
			const parts = role.split(':');
			if (parts.length === 2) {
				return userAttr[parts[0]]?.includes(parts[1]);
			}
			return userAuthority.includes(role);
		});
	}, [authority, userAuthority]);

	if (isEmpty(authority) || isEmpty(userAuthority) || typeof authority === 'undefined') {
		return !emptyCheck;
	}

	return roleMatched;
}

export default useAuthority;
