import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    selectedDashboard: 'Plataforma'
}

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload 
        },
        setSelectedDashboard: (state, action) => {
            state.selectedDashboard = action.payload
        }
    },
})

export const { setCurrentRouteKey, setSelectedDashboard } = commonSlice.actions

export default commonSlice.reducer
