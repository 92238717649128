import {
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { Alert, Checkbox, Input, Pagination, Select, Table } from 'components/ui';
import PropTypes from 'prop-types';
import { Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Loading from './Loading';
import TableRowSkeleton from './loaders/TableRowSkeleton';

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = (props) => {
	const { indeterminate, onChange, onCheckBoxChange, onIndeterminateCheckBoxChange, ...rest } = props;

	const ref = useRef(null);

	useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
	}, [ref, indeterminate]);

	const handleChange = (e) => {
		onChange(e);
		onCheckBoxChange?.(e);
		onIndeterminateCheckBoxChange?.(e);
	};

	return <Checkbox className='mb-0' ref={ref} onChange={(_, e) => handleChange(e)} {...rest} />;
};

function useSkipper() {
	const shouldSkipRef = useRef(true);
	const shouldSkip = shouldSkipRef.current;

	// Wrap a function with this to skip a pagination reset temporarily
	const skip = useCallback(() => {
		shouldSkipRef.current = false;
	}, []);

	useEffect(() => {
		shouldSkipRef.current = true;
	});

	return [shouldSkip, skip];
}

const DataTable = forwardRef((props, ref) => {
	const {
		skeletonAvatarColumns,
		columns: columnsProp,
		data,
		loading,
		onCheckBoxChange,
		onIndeterminateCheckBoxChange,
		onPaginationChange,
		onSelectChange,
		onSort,
		pageSizes,
		selectable,
		skeletonAvatarProps,
		pagingData,
		disabledPagination,
		className,
		compact,
		setData,
		getRowCanExpand,
		renderRowSubComponent,
	} = props;

	const { pageSize, pageIndex, total } = pagingData;

	const [sorting, setSorting] = useState(null);

	const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

	const pageSizeOption = useMemo(
		() =>
			pageSizes.map((number) => ({
				value: number,
				label: `${number} / página`,
			})),
		[pageSizes]
	);

	const handleCheckBoxChange = (checked, row) => {
		if (!loading) {
			onCheckBoxChange?.(checked, row);
		}
	};

	const handleIndeterminateCheckBoxChange = (checked, rows) => {
		if (!loading) {
			onIndeterminateCheckBoxChange?.(checked, rows);
		}
	};

	const handlePaginationChange = (page) => {
		if (!loading) {
			onPaginationChange?.(page);
		}
	};

	const handleSelectChange = (value) => {
		if (!loading) {
			onSelectChange?.(Number(value));
		}
	};

	useEffect(() => {
		if (Array.isArray(sorting)) {
			const sortOrder = sorting.length > 0 ? (sorting[0].desc ? 'desc' : 'asc') : '';
			const id = sorting.length > 0 ? sorting[0].id : '';
			onSort?.({ order: sortOrder, key: id });
		}
	}, [sorting]);

	const hasOldColumnMetaKey = columnsProp.some((col) => col.Header || col.accessor || col.Cell);

	const finalColumns = useMemo(() => {
		const columns = columnsProp;

		if (selectable) {
			return [
				{
					id: 'select',
					header: ({ table }) => (
						<IndeterminateCheckbox
							checked={table.getIsAllRowsSelected()}
							indeterminate={table.getIsSomeRowsSelected()}
							onChange={table.getToggleAllRowsSelectedHandler()}
							onIndeterminateCheckBoxChange={(e) => {
								handleIndeterminateCheckBoxChange(e.target.checked, table.getRowModel().rows);
							}}
						/>
					),
					cell: ({ row }) => (
						<IndeterminateCheckbox
							checked={row.getIsSelected()}
							disabled={!row.getCanSelect()}
							indeterminate={row.getIsSomeSelected()}
							onChange={row.getToggleSelectedHandler()}
							onCheckBoxChange={(e) => handleCheckBoxChange(e.target.checked, row.original)}
						/>
					),
				},
				...columns,
			];
		}
		return columns;
	}, [columnsProp, selectable]);

	const table = useReactTable({
		data,
		columns: hasOldColumnMetaKey ? [] : finalColumns,
		getRowCanExpand,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		manualPagination: true,
		manualSorting: true,
		onSortingChange: setSorting,
		state: {
			sorting,
		},
		autoResetPageIndex,
		meta: {
			updateData: (rowIndex, columnId, value) => {
				// Skip page index reset until after next rerender
				skipAutoResetPageIndex();
				setData((old) =>
					old.map((row, index) => {
						if (index === rowIndex) {
							return {
								...old[rowIndex],
								[columnId]: value,
							};
						}
						return row;
					})
				);
			},
		},
	});

	const resetSorting = () => {
		table.resetSorting();
	};

	const resetSelected = () => {
		table.toggleAllRowsSelected(false);
	};

	useImperativeHandle(ref, () => ({
		resetSorting,
		resetSelected,
	}));

	if (hasOldColumnMetaKey) {
		const message =
			'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8';

		if (process.env.NODE_ENV === 'development') {
			console.warn(message);
		}

		return <Alert>{message}</Alert>;
	}

	const tableClass = classNames(compact && 'table-compact', className);

	return (
		<Loading loading={loading && data.length !== 0} type='cover'>
			<Table className={tableClass}>
				<THead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								const { className: headerClassName, ...rest } = header.column.columnDef.headerProps || {};
								return (
									<Th key={header.id} colSpan={header.colSpan} {...rest}>
										{header.isPlaceholder ? null : (
											<div
												className={classNames(
													header.column.getCanSort() && 'cursor-pointer select-none point',
													loading && 'pointer-events-none',
													headerClassName
												)}
												onClick={header.column.getToggleSortingHandler()}
											>
												{flexRender(header.column.columnDef.header, header.getContext())}
												{header.column.getCanSort() && <Sorter sort={header.column.getIsSorted()} />}
											</div>
										)}
									</Th>
								);
							})}
						</Tr>
					))}
				</THead>
				{loading && data.length === 0 ? (
					<TableRowSkeleton
						columns={finalColumns.length}
						rows={pagingData.pageSize}
						avatarInColumns={skeletonAvatarColumns}
						avatarProps={skeletonAvatarProps}
					/>
				) : (
					<TBody>
						{table
							.getRowModel()
							.rows.slice(0, pageSize)
							.map((row) => {
								// console.log('row.getIsExpanded()', row.getIsExpanded());
								return (
									<Fragment key={row.id}>
										<Tr>
											{row.getVisibleCells().map((cell) => {
												const { className: cellClassName, ...rest } = cell.column.columnDef.cellProps || {};
												return (
													<Td key={cell.id} className={classNames(cellClassName)} {...rest}>
														{flexRender(cell.column.columnDef.cell, cell.getContext())}
													</Td>
												);
											})}
										</Tr>
										{row.getIsExpanded() && (
											<Tr>
												{/* 2nd row is a custom 1 cell row */}
												<Td colSpan={row.getVisibleCells().length} className='bg-gray-200'>
													{renderRowSubComponent({ row })}
												</Td>
											</Tr>
										)}
									</Fragment>
								);
							})}
					</TBody>
				)}
			</Table>
			{!(disabledPagination === true) && (
				<div className='flex items-center justify-between mt-4'>
					<Pagination pageSize={pageSize} currentPage={pageIndex} total={total} onChange={handlePaginationChange} />
					<div style={{ minWidth: 130 }}>
						<Select
							size='sm'
							menuPlacement='top'
							isSearchable={false}
							value={pageSizeOption.filter((option) => option.value === pageSize)}
							options={pageSizeOption}
							onChange={(option) => handleSelectChange(option.value)}
						/>
					</div>
				</div>
			)}
		</Loading>
	);
});

DataTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	loading: PropTypes.bool,
	onCheckBoxChange: PropTypes.func,
	onIndeterminateCheckBoxChange: PropTypes.func,
	onPaginationChange: PropTypes.func,
	onSelectChange: PropTypes.func,
	onSort: PropTypes.func,
	pageSizes: PropTypes.arrayOf(PropTypes.number),
	selectable: PropTypes.bool,
	skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
	skeletonAvatarProps: PropTypes.object,
	pagingData: PropTypes.shape({
		total: PropTypes.number,
		pageIndex: PropTypes.number,
		pageSize: PropTypes.number,
	}),
};

DataTable.defaultProps = {
	pageSizes: [10, 25, 50, 100],
	pagingData: {
		total: 0,
		pageIndex: 1,
		pageSize: 10,
	},
	data: [],
	columns: [],
	selectable: false,
	loading: false,
};

export const EditableCell = ({ getValue, row: { index }, column: { id }, table, className }) => {
	const initialValue = getValue();
	// We need to keep and update the state of the cell normally
	const [value, setValue] = useState(initialValue);

	// When the input is blurred, we'll call our table meta's updateData function
	const onBlur = () => {
		table.options.meta?.updateData(index, id, value);
	};

	// If the initialValue is changed external, sync it up with our state
	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return (
		<Input
			size='sm'
			className={className}
			value={value}
			onChange={(e) => {
				setValue(e.target.value);
			}}
			onBlur={onBlur}
		/>
	);
};

export default DataTable;
