/*
URL FRONT-END
*/
export const url_panel = '/panel';
export const url_invoices = '/invoices';


/*
URL BACK-END
*/
export const api_modal = `/modal`;
export const api_country = `/country`;
export const api_marketNews = `/marketNews`;
export const api_agents = `/agent`;
export const api_users = `/user`;
export const api_integrators = `/integrator`;
export const api_partners = `/partner`;
export const api_operation = `/operation`;
export const api_freightType = `/freightType`;
export const api_currency = `/currency`;
export const api_suppliers = `/customerSupplier`;
export const api_paymentStatus = `/paymentStatus`;
export const api_tax = `/tax`;
export const api_expense = `/expense`;
export const api_serviceType = `/serviceType`;
export const api_loadType = `/loadType`;
export const api_incoterm = `/incoterm`;
export const api_customer = `/customer`;
export const api_locationType = `/locationType`;
export const api_containerType = `/containerType`;
export const api_documentType = `/documentType`;
export const api_platform_dashboard_use = `/dashboard/platform-dashboard-use`;
export const api_packageType = `/PackageType`;
export const api_aditionalService = `/AditionalService`;
export const api_location = `/location`;
export const api_authenticate = `/authenticate`;
export const api_authenticate_reset_internal = `/authenticate/reset-password-internal`;
export const api_notification = `/notification`;
export const api_notification_readAll = `/notification/readAll`;
export const api_notification_read = `/notification/read`;
export const api_general_search = `/generalsearch/search`;
export const api_notification_count = `/notification/count`;
export const api_quotation_status = `/quotationStatus`;
export const api_external_data = `/externalData`;
export const api_shipment_status = `/shipmentStatus`;
export const api_carrier = `/carrier`;
export const api_quotations = `/quotation`;
export const api_quotations_list = `/quotation/list`;
export const api_quotation_history = `/QuotationHistory`;
export const api_quotation_attachment = `/QuotationAttachment`;
export const api_shipment_attachment = `/ShipmentAttachment`;
export const api_quotation_result = `/QuotationResult`;
export const api_quotation_send_message = `/QuotationHistory/sendMessage`;
export const api_quotation_create = `/quotation/request-quotation`;
export const api_quotation_approve = `/quotation/approve`;
export const api_quotation_reject = `/quotation/reject`;
export const api_quotation_attachment_remove = `/QuotationAttachment/remove`;
export const api_quotation_result_attachment_remove = `/QuotationResultAttachment/remove`;
export const api_quotation_result_attachment_download = `/QuotationResultAttachment/download`;
export const api_quotation_attachment_download = `/QuotationAttachment/download`;
export const api_shipment_attachment_download = `/ShipmentAttachment/download`;
export const api_shipment_attachment_download_all = `/ShipmentAttachment/download-all`;
export const api_shipment_volume_invoice_items = `/ShipmentVolumeInvoiceItem`;
export const api_shipment_attachment_remove = `/ShipmentAttachment/remove`;
export const api_shipment_volume_add = `/ShipmentVolume/add`;
export const api_shipment_volume_invoice_add = `/ShipmentVolumeInvoice/add`;
export const api_shipment_tax_add = `/ShipmentTax/add`;
export const api_shipment_expense_add = `/ShipmentExpense/add`;
export const api_shipment_cash_add = `/ShipmentCash/add`;
export const api_shipment_volume_remove = `/ShipmentVolume/remove`;
export const api_shipment_tax_remove = `/ShipmentTax/remove`;
export const api_shipment_expense_remove = `/ShipmentExpense/remove`;
export const api_shipment_cash_remove = `/ShipmentCash/remove`;
export const api_shipment_volume_invoice_remove = `/ShipmentVolumeInvoice/remove`;
export const api_shipment_invoice_item_remove = `/ShipmentVolumeInvoiceItem/remove`;
export const api_quotation_attachment_add = `/QuotationAttachment/add`;
export const api_quotation_result_upsert = `/QuotationResult/upsert`;
export const api_shipment_attachment_add = `/ShipmentAttachment/add`;
export const api_shipment_volume_invoice_item_add = `/ShipmentVolumeInvoiceItem/add`;
export const api_shipment_update = `/Shipment/update`;
export const api_shipment_create = `/Shipment/create`;
export const api_shipment_update_step = `/ShipmentStep/update`;
export const api_shipment_update_actual_step = `/Shipment/step`;
export const api_shipment_update_limit_time_arrive = `/Shipment/update-limit-time-arrive`;
export const api_shipment_update_partial = `/Shipment/update-partial`;
export const api_shipment_milestone_next_step = `/ShipmentMilestone/step`;
export const api_shipment_milestone_return_step = `/ShipmentMilestone/return-step`;
export const api_shipment_milestone_step_order = `/ShipmentMilestone/step-order`;
export const api_shipment_milestone_step_status = `/ShipmentMilestone/step-status`;
export const api_shipments = `/shipment`;
export const api_shipment_pendings = `/shipment/shipmentPendings`;
export const api_shipment_withpendings = `/shipment/pendings`;
export const api_shipment_process = `/shipment/process`;
export const api_shipment_finance = `/shipment/finance`;
export const api_shipment_milestone = `/ShipmentMilestone`;
export const api_shipment_pending = `/ShipmentPending`;
export const api_shipment_my_pending = `/ShipmentPending/my`;
export const api_shipment_taxes = `/shipmentTax`;
export const api_shipment_cash = `/shipmentCash`;
export const api_shipment_expenses = `/shipmentExpense`;
export const api_shipment_list = `/shipment/list`;
export const api_shipment_orderitems = `/shipment/orderitems`;
export const api_dashboard = `/dashboard`;
export const api_dashboard_shipment_statistics = `/dashboard/shipment-statistics`;
export const api_shipment_history = `/ShipmentHistory`;
export const api_shipment_send_message = `/ShipmentHistory/sendMessage`;
export const api_milestone_type = `/MilestoneType`;
export const api_order_items = `/OrderItem`;
export const api_customer_manufacturer = `/CustomerManufacturer`;
export const api_customer_provider = `/CustomerProvider`;
export const api_customer_product = `/CustomerProduct`;
export const api_customer_customer = `/CustomerCustomer`;
export const api_customer_proposal = `/CustomerProposal`;
export const api_customer_carrier = `/CustomerCarrier`;

export const api_vsantos_process = `/VSantos/process/list`;
export const api_vsantos_get_empresa = `/VSantos/process/empresa`;
export const api_vsantos_get_imposto = `/VSantos/process/imposto`;
export const api_vsantos_get_carga = `/VSantos/process/carga`;
export const api_vsantos_get_cct = `/VSantos/process/cct`;
export const api_vsantos_get_desembaraco = `/VSantos/process/desembaraco`;
export const api_vsantos_get_mercante = `/VSantos/process/mercante`;
export const api_vsantos_get_invoices = `/VSantos/process/invoices`;
export const api_vsantos_get_observacoes = `/VSantos/process/observacoes`;

export const api_vsantos_get_mantra = `/VSantos/process/mantra`;
export const api_vsantos_get_dashboard = `/VSantos/process/dashboard`;
export const api_vsantos_get_despesas = `/VSantos/process/despesas`;
export const api_vsantos_get_numerarios = `/VSantos/process/numerarios`;

export const api_vsantos_get_arquivos = `/VSantos/process/arquivos`;
export const api_vsantos_get_download = `/VSantos/process/download`;
export const api_vsantos_get_custo_por_item = `/VSantos/process/custoporitem`;

export const api_vsantos_get_custo_por_processo = `/VSantos/process/CustoPorProcesso`;
export const api_vsantos_get_performance_aerea = `/VSantos/process/PerformanceAerea`;
export const api_vsantos_get_performance_maritima = `/VSantos/process/PerformanceMaritima`;
export const api_vsantos_get_performance_rodoviario = `/VSantos/process/PerformanceRodoviario`;


export const api_history_tractian = `/HistoryTractian`;
