import { LAYOUT_TYPE_AUTH } from 'constants/theme.constant'
import React from 'react'
import authRoute from './authRoute'

export const publicRoutes = [
    ...authRoute,
    {
        key: 'reply',
        path: '/reply/:token',
        component: React.lazy(() => import('views/reply')),
        authority: [],
        layout: LAYOUT_TYPE_AUTH
    },
]

export const protectedRoutes = [
    {
        key: 'home',
        path: '/home',
        component: React.lazy(() => import('views/home-page')),
        authority: [],
    },
    {
        key: 'newQuotation',
        path: '/new-quotation',
        component: React.lazy(() => import('views/quotation/components/newQuotation')),
        authority: ["user","user_pharmedical","user_cometrix_vsantos"],
    },
    {
        key: 'quotations',
        path: '/quotations',
        component: React.lazy(() => import('views/quotation/listQuotations')),
        authority: ["admin"],
    },
    {
        key: 'myQuotations',
        path: '/my-quotations',
        component: React.lazy(() => import('views/quotation/listQuotations')),
        authority: ["user","user_pharmedical","operator","user_cometrix_vsantos"],
    },
    {
        key: 'myProcess',
        path: '/my-process',
        component: React.lazy(() => import('views/process')),
        authority: ["user","user_pharmedical","user_cometrix_vsantos"],
    },
    {
        key: 'VSantosProcessos',
        path: '/processos',
        component: React.lazy(() => import('views/vsantos/process/processList')),
        authority: ["vsantos_customer","user_cometrix_vsantos"],
    },
    {
        key: 'VSantosProcessosDetalhe',
        path: '/process-detail/:processNumber/:processYear',
        component: React.lazy(() => import('views/vsantos/process/processDetail')),
        authority: ["vsantos_customer","user_cometrix_vsantos"],
    },
    {
        key: 'VSantosKpiAereo',
        path: '/kpi-aereo',
        component: React.lazy(() => import('views/vsantos/process/components/kpiAereo')),
        authority: ["vsantos_customer","user_cometrix_vsantos"],
    },
    {
        key: 'VSantosKpiMaritimo',
        path: '/kpi-maritimo',
        component: React.lazy(() => import('views/vsantos/process/components/kpiMaritimo')),
        authority: ["vsantos_customer","user_cometrix_vsantos"],
    },
    {
        key: 'VSantosKpiRodoviario',
        path: '/kpi-rodoviario',
        component: React.lazy(() => import('views/vsantos/process/components/kpiRodoviario')),
        authority: ["vsantos_customer","user_cometrix_vsantos"],
    },
    {
        key: 'VSantosReportCustoProcesso',
        path: '/custo-processo',
        component: React.lazy(() => import('views/vsantos/process/components/reportCustoProcesso')),
        authority: ["vsantos_customer","user_cometrix_vsantos"],
    },
    {
        key: 'VSantosReportCustoItem',
        path: '/custo-item',
        component: React.lazy(() => import('views/vsantos/process/components/reportCustoItem')),
        authority: ["vsantos_customer","user_cometrix_vsantos"],
    },
    {
        key: 'finance',
        path: '/finance',
        component: React.lazy(() => import('views/finance')),
        authority: ["user","user_pharmedical","admin","user_cometrix_vsantos"],
    },
    {
        key: 'shipment',
        path: '/shipments',
        component: React.lazy(() => import('views/shipment/listShipments')),
        authority: [],
    },
    {
        key: 'shipmentView',
        path: '/shipment-view/:id',
        component: React.lazy(() => import('views/shipment/shipmentView')),
        authority: [],
    },
    {
        key: 'quotationView',
        path: '/quotation-view/:id',
        component: React.lazy(() => import('views/quotation/quotationView')),
        authority: [],
    },
    {
        key: 'myShipment',
        path: '/my-shipments',
        component: React.lazy(() => import('views/shipment/listShipments')),
        authority: ["user","user_pharmedical","operator", "agent","user_cometrix_vsantos"],
    },
    {
        key: 'myReports',
        path: '/reports/my-reports',
        component: React.lazy(() => import('views/reports/myReports')),
        authority: ["user","user_pharmedical","user_cometrix_vsantos"],
    },
    {
        key: 'myPersonalizedReports',
        path: '/reports/my-personalized-reports',
        component: React.lazy(() => import('views/reports/myPersonalizedReports')),
        authority: ["user","user_pharmedical","user_cometrix_vsantos"],
    },
    {
        key: 'reportContainer',
        path: '/reports/report-container',
        component: React.lazy(() => import('views/reports')),
        authority: ["user","user_pharmedical","user_cometrix_vsantos"],
    },
    {
        key: 'profile',
        path: '/profile',
        component: React.lazy(() => import('views/users/profile')),
        authority: [],
    },
    {
        key: 'myPendings',
        path: '/my-pendings',
        component: React.lazy(() => import('views/pendings/listPendings')),
        authority: [],
    },  
    {
        key: 'myNotifications',
        path: '/my-notifications',
        component: React.lazy(() => import('views/notifications/listNotifications')),
        authority: [],
    },  
    {
        key: 'myNotificationsView',
        path: '/my-notifications/:id',
        component: React.lazy(() => import('views/notifications/listNotifications')),
        authority: [],
    },  
    {
        key: 'customers',
        path: '/customers',
        component: React.lazy(() => import('views/customers')),
        authority: ["admin"],
    },   
    {
        key: 'users',
        path: '/users',
        component: React.lazy(() => import('views/users')),
        authority: ["admin"],
    },
    {
        key: 'reports',
        path: '/reports',
        component: React.lazy(() => import('views/reports')),
        authority: [],
    },
    {
        key: 'config.containerTypes',
        path: '/config/container-types',
        component: React.lazy(() => import('views/config/containerType')),
        authority: [],
    },
    {
        key: 'config.country',
        path: '/config/countries',
        component: React.lazy(() => import('views/config/country')),
        authority: [],
    },
    {
        key: 'config.carrier',
        path: '/config/carriers',
        component: React.lazy(() => import('views/config/carrier')),
        authority: [],
    },
    {
        key: 'config.expenses',
        path: '/config/expenses',
        component: React.lazy(() => import('views/config/expenseType')),
        authority: [],
    },
    {
        key: 'config.marketNews',
        path: '/config/market-news',
        component: React.lazy(() => import('views/config/marketNews')),
        authority: [],
    },
    {
        key: 'config.incoterms',
        path: '/config/incoterms',
        component: React.lazy(() => import('views/config/incoterm')),
        authority: [],
    },
    {
        key: 'config.loadType',
        path: '/config/load-types',
        component: React.lazy(() => import('views/config/loadType')),
        authority: [],
    },
    {
        key: 'config.locationTypes',
        path: '/config/location-types',
        component: React.lazy(() => import('views/config/locationType')),
        authority: [],
    },
    {
        key: 'config.locations',
        path: '/config/locations',
        component: React.lazy(() => import('views/config/location')),
        authority: [],
    },
    {
        key: 'config.modals',
        path: '/config/modals',
        component: React.lazy(() => import('views/config/modal')),
        authority: [],
    },
    {
        key: 'config.packageTypes',
        path: '/config/package-types',
        component: React.lazy(() => import('views/config/packageType')),
        authority: [],
    },
    {
        key: 'config.documentTypes',
        path: '/config/document-types',
        component: React.lazy(() => import('views/config/documentType')),
        authority: [],
    },
    {
        key: 'config.customerManufacturer',
        path: '/config/customer-manufacturer',
        component: React.lazy(() => import('views/config/customerManufacturer')),
        authority: [],
    },
    {
        key: 'config.customerCustomer',
        path: '/config/customer-customer',
        component: React.lazy(() => import('views/config/customerCustomer')),
        authority: [],
    },
    {
        key: 'config.customerProvider',
        path: '/config/customer-provider',
        component: React.lazy(() => import('views/config/customerProvider')),
        authority: [],
    },
    {
        key: 'config.customerProduct',
        path: '/config/customer-product',
        component: React.lazy(() => import('views/config/customerProduct')),
        authority: [],
    },
    {
        key: 'config.customerProposal',
        path: '/config/customer-proposal',
        component: React.lazy(() => import('views/config/customerProposal')),
        authority: [],
    },
    {
        key: 'pendings.createPending',
        path: '/pendings/create-pending',
        component: React.lazy(() => import('views/config/pendings/components/pendingForm')),
        authority: [],
    },
    {
        key: 'notifications.createNotification',
        path: '/notifications/create-notification',
        component: React.lazy(() => import('views/config/notification/components/notificationForm')),
        authority: [],
    },
    {
        key: 'myAgents',
        path: '/agents',
        component: React.lazy(() => import('views/config/agent')),
        authority: [],
    },
    {
        key: 'mySuppliers',
        path: '/suppliers',
        component: React.lazy(() => import('views/config/supplier')),
        authority: [],
    },
    {
        key: 'myOperators',
        path: '/operators',
        component: React.lazy(() => import('views/config/partner')),
        authority: [],
    },
    {
        key: 'integrators',
        path: '/integrators',
        component: React.lazy(() => import('views/config/integrator')),
        authority: [],
    },
    {
        key: 'orders',
        path: '/orders/items',
        component: React.lazy(() => import('views/orders')),
        authority: ["user","user_pharmedical","user_cometrix_vsantos"],
    },
    {
        key: 'financeOrders',
        path: '/orders/finance',
        component: React.lazy(() => import('views/orders/financeOrderList')),
        authority: ["user","user_pharmedical","user_cometrix_vsantos"],
    },
    {
        key: 'mySettings',
        path: '/settings',
        component: React.lazy(() => import('views/customers/customerSettings')),
        authority: ["user","user_pharmedical","user_cometrix_vsantos"],
    },
    {
        key: 'ShipmentPendings',
        path: '/shipment-pendings',
        component: React.lazy(() => import('views/shipment/shipmentPendings')),
        authority: ["user","user_pharmedical","user_cometrix_vsantos"],
    },
    {
        key: 'dashboardUso',
        path: '/config/use-dashboard',
        component: React.lazy(() => import('views/config/dashboardUso')),
        authority: ["admin"],
    },
    {
        key: 'historyTractian',
        path: '/historical/tractian',
        component: React.lazy(() => import('views/historical/tractian')),
        authority: ["email:tractian"],
    },
]
