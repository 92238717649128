import React from 'react'
import PropTypes from 'prop-types'
import PatternFormat from 'react-number-format'
import { Input } from 'components/ui'

const PatternInput = ({ inputSuffix, inputPrefix, ...props }) => {
    return (
        <Input
            {...props}
            value={props.value}
            suffix={inputSuffix}
            prefix={inputPrefix}
        />
    )
}

const PatternFormatInput = ({ onValueChange, form, field, ...rest }) => {
    return (
        <PatternFormat
            customInput={PatternInput}
            onValueChange={onValueChange}
            form={form}
            field={field}
            onBlur={field?.onBlur}
            {...rest}
        />
    )
}

const FormPatternInput = ({
    form,
    field,
    inputSuffix,
    inputPrefix,
    onValueChange,
    pattern,
    ...rest
}) => {
    return (
        <PatternFormatInput
            form={form}
            field={field}
            inputPrefix={inputPrefix}
            inputSuffix={inputSuffix}
            onValueChange={onValueChange}
            format={pattern}
            {...rest}
        />
    )
}

FormPatternInput.propTypes = {
    form: PropTypes.object,
    field: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.any,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        pattern: PropTypes.string,
    }),
    inputSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    inputPrefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default FormPatternInput
