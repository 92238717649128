import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import api, { endpoint } from 'services/api'

function useAuth() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            
            return api.post(endpoint.api_authenticate, values)
            .then((result) => {
                if (result.data){
                    var data = result.data;

                    const { accessToken, user } = data.data;
                    dispatch(onSignInSuccess(accessToken));
                    
                    var userMerged = user;
                    userMerged.authority = [user.authority];
                    dispatch(setUser(userMerged));

                    const redirectUrl = query.get(REDIRECT_URL_KEY)
                    navigate(
                        redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                    )
                    return {
                        status: 'success',
                        message: '',
                    }
                }
            })
            .catch(error => {
                console.log(error);
                if (error?.response?.data){
                    var data = error.response.data;
                    if (data?.hasErrors){
                        return {
                            status: 'failed',
                            message: data?.errors[0].message || errors.toString(),
                        }
                    }
                }
            });
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: resp.data.user.authority,
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        // await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
