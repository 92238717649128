export default function acronym(name = '', size = 0) {
    const shortName = name.match(/\b(\w)/g)

    if (shortName) {
        if (size !== 0)
            return shortName.splice(0,2).join('');
        
        return shortName.join('')
    }

    return name
}
