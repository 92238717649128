import React from 'react'
import PropTypes from 'prop-types'
import { Card, Skeleton } from 'components/ui'
import MediaSkeleton from './MediaSkeleton'

const CardSkeleton = (props) => {
    const { showAvatar, rows} = props;

    var rowCards = Array.apply(null,{length: rows}).map(Number.call, Number);

    return (
        rowCards.map((row, index) => {
            return (
                <Card key={index} className="mt-1 cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700">
                    <div className="flex flex-auto items-center gap-2">
                        {
                            showAvatar &&
                            <div>
                                <Skeleton variant="circle" />
                            </div>
                        }

                        <div className="flex flex-col gap-2 w-full ml-4">
                            <Skeleton width="30%" />
                            <Skeleton width="40%" />
                            <Skeleton width="10%" />
                        </div>
                    </div>
                </Card>
            )
        })
        
    )
}

CardSkeleton.defaultProps = {
    showAvatar: true,
    rows: 1
}

CardSkeleton.propTypes = {
    showAvatar: PropTypes.bool,
    rows: PropTypes.number
}

export default CardSkeleton