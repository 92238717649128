import { createServer } from 'miragejs'
import appConfig from 'configs/app.config'

import { signInUserData } from './data/authData'

import { authFakeApi, commonFakeApi, crmFakeApi, masterDataFakeApi, quotationFakeApi } from './fakeApi'

import { notificationListData, searchQueryPoolData } from './data/commonData'
import { eventsData, mailData, crmDashboardData } from './data/crmData'
import { operations, modals, freightTypes, currencies, quotationStatus, shipmentStatus, countries,
    containerTypes,
    documentTypes,
    incoterms,
    loadTypes,
    locationTypes,
    locations,
    packageTypes,
    customers
} from './data/masterData'
import { quotationData } from './data/quotationData'
import { shipmentData } from './data/shipmentData'
import { notifications } from './data/notificationsData'
import shipmentFakeApi from './fakeApi/shipmentFakeApi'
import notificationFakeApi from './fakeApi/notificationFakeApi'
import pendingFakeApi from './fakeApi/pendingFakeApi'
import { pendings } from './data/pendingsData'

const { apiPrefix } = appConfig

export default function mockServer({ environment = 'test' }) {
    return createServer({
        environment,
        seeds(server) {
            server.db.loadData({
                signInUserData,
                notificationListData,
                searchQueryPoolData,
                crmDashboardData,
                quotationData,
                shipmentData,
                operations,
                modals,
                freightTypes,
                currencies,
                quotationData,
                quotationStatus,
                shipmentStatus,
                countries,
                containerTypes,
                documentTypes,
                incoterms,
                loadTypes,
                locationTypes,
                locations,
                packageTypes,
                customers,
                notifications,
                pendings
            })
        },
        routes() {
            this.urlPrefix = ''
            this.namespace = ''
            this.passthrough((request) => {
                let isExternal = request.url.startsWith('http')
                return isExternal
            })
            this.passthrough()

            authFakeApi(this, apiPrefix)
            commonFakeApi(this, apiPrefix)
            crmFakeApi(this, apiPrefix)
            masterDataFakeApi(this, apiPrefix)
            quotationFakeApi(this,apiPrefix)
            shipmentFakeApi(this,apiPrefix)
            notificationFakeApi(this, apiPrefix)
            pendingFakeApi(this, apiPrefix)
        },
    })
}
